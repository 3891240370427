import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  FunctionField,
  Filter,
} from 'react-admin';
import {
  ReviewTypes,
  ReviewStatus,
} from '../constants/Review';

const AverageReviewFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Төрөл"
      emptyText="Бүгд"
      source="revieweeType"
      choices={ReviewTypes}
      fullWidth
      alwaysOn
    />
    <SelectInput
      label="Төлөв"
      emptyText="Бүгд"
      source="status"
      choices={ReviewStatus}
      fullWidth
      alwaysOn
    />
  </Filter>
);

const getTypeById = (id) => ReviewTypes.find(type => type.id === id).name;
const getStatusById = (id) => ReviewStatus.find(type => type.id === id).name;

export const AverageReviewList = props => (
  <List {...props} filters={<AverageReviewFilter />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <FunctionField label="Үнэлгээ" render={record => `${record.rate} / 5`} />
      <FunctionField label="Төрөл" render={record => getTypeById(record.revieweeType)} />
      <TextField label="Төрөл ID" source="revieweeId" />
      <FunctionField label="Төлөв" render={record => getStatusById(record.status)} />
    </Datagrid>
  </List>
);

