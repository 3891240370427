export const MENTOR_TOPICS = [
    { id: 'Эсээ бичих', name: 'Эсээ бичих' },
    { id: 'Мэргэжил сонгох', name: 'Мэргэжил сонгох' },
    { id: 'Бие даан амьдрах', name: 'Бие даан амьдрах' },
    { id: 'Сурангаа ажиллах', name: 'Сурангаа ажиллах' },
    { id: 'Тэтгэлэг авах', name: 'Тэтгэлэг авах' },
    { id: 'Их сургуулийн амьдрал', name: 'Их сургуулийн амьдрал' },
    { id: 'Хувь хүний хөгжил', name: 'Хувь хүний хөгжил' },
    { id: 'Өргөдөл илгээх', name: 'Өргөдөл илгээх' },
    { id: 'Гадаад хэл сурах', name: 'Гадаад хэл сурах' },
    { id: 'Шалгалтад бэлдэх', name: 'Шалгалтад бэлдэх' },
    { id: 'Гадаадад сурах', name: 'Гадаадад сурах' },
    { id: 'Карьер хөгжүүлэлт', name: 'Карьер хөгжүүлэлт' },
  ];

export const MENTOR_QNA_STATUSES = [
  'pending',
  'Approved',
  'Answered',
];
