import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  NumberInput,
  FileInput,
  FileField,
  ImageField,
  FormDataConsumer,
  BooleanInput,
  DateTimeInput,
  ReferenceInput,
  Filter,
  CreateButton,
  ExportButton,
  required
} from 'react-admin';
import { CONTENT_TYPES } from '../constants/ContentType';
import { COUNTRY_NAMES } from '../constants';
import { MENTOR_TOPICS } from '../constants/AskingMentor';

const JourneyStepFilter = (props) => (
  <Filter {...props}>
    <TextInput label="journeyId" source="journeyId" alwaysOn /> 
  </Filter>
);

const JourneyStepActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const ListFilterInput = props => {
  const {contentType, ...rest} = props;
  if(contentType === CONTENT_TYPES[7].id) {
    return (
      <React.Fragment>
        <SelectInput
            label="Улс"
            source="country"
            choices={COUNTRY_NAMES}
            {...rest}
        />
        <SelectInput
            label="Асуултын төрөл"
            source="listFilter"
            choices={MENTOR_TOPICS}
            {...rest}
        />
      </React.Fragment>
    );
  } else if(contentType === CONTENT_TYPES[2].id || contentType === CONTENT_TYPES[3].id) {
    return (
      <SelectInput
        label="Улс"
        source="listFilter"
        choices={COUNTRY_NAMES}
        {...rest}
      />
    )
  }
  return null;
}

export const JourneyStepList = props => (
  <List {...props} filters={<JourneyStepFilter/>} actions={<JourneyStepActions props={props} />}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="title" source="title" />
      <TextField label="type" source="contentType" />
      <TextField label="contentId" source="contentId" />
      <TextField label="journeyId" source="journeyId" />
      <TextField label="order" source="order" />
      <EditButton />
    </Datagrid>
  </List>
);

const Title = ({ record }) => {
  const { title } = record;
  return <span>Journey Step {record ? `"${title}"` : ''}</span>;
};

export const JourneyStepEdit = props => {
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <TextInput disabled source="id" />
        <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
        <SelectInput label="Төрөл" source="contentType" choices={CONTENT_TYPES} />
        <FormDataConsumer>
          {({ formData, ...rest }) => 
            <ListFilterInput contentType={formData && formData.contentType} {...rest} />
          }
        </FormDataConsumer>
        <TextInput fullWidth label="title" source="title" />
        <ReferenceInput fullWidth source="journeyId" reference="journeys" perPage={100}>
          <SelectInput optionText="title" optionValue="id" />  
        </ReferenceInput> 
        {/* <ImageField title="Зураг" source="thumbnail" />
        <TextInput fullWidth label="Зураг" source="thumbnail" /> */}
        <ImageField source="thumbnail" title="Зураг" />
        <FileInput source="file" label="Зураг" accept="image/*">
          <FileField source="image" title="image" />
        </FileInput>
        <TextInput label="duration" source="duration" />
        <TextInput fullWidth label="homeworkLink" source="homeworkLink" />
        <FileInput source="homeworkFile" label="Гэрийн даалгавар" accept="application/pdf" >
          <FileField source="src" title="title" />
        </FileInput>
        <NumberInput label="order" source="order" />
        <NumberInput label="ContentId" source="contentId" validate={[required()]} />
        <DateTimeInput label="Start At" helperText="Улаанбаатарын (+08:00) цагаар" source="startAt"/>
      </SimpleForm>
    </Edit>
  )
}

export const JourneyStepCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <CloneButton />
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <SelectInput label="Төрөл" source="contentType" choices={CONTENT_TYPES} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          <ListFilterInput contentType={formData && formData.contentType} {...rest} />
        }
      </FormDataConsumer>
      <TextInput fullWidth label="title" source="title" />
      <ReferenceInput fullWidth source="journeyId" reference="journeys" perPage={100}>
        <SelectInput optionText="title" optionValue="id" />  
      </ReferenceInput> 
      {/* <ImageField title="Зураг" source="thumbnail" />
      <TextInput fullWidth label="Зураг" source="thumbnail" /> */}
      <ImageField source="thumbnail" title="Зураг" />
      <FileInput fullWidth source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput label="duration" source="duration" />
      <FileInput source="homeworkFile" label="Гэрийн даалгавар" accept="application/pdf" >
        <FileField source="src" title="title" />
      </FileInput>
      <NumberInput label="order" source="order" />
      <NumberInput label="ContentId" source="contentId" validate={[required()]} />
      <DateTimeInput label="Start At" helperText="Улаанбаатарын (+08:00) цагаар" source="startAt"/>
    </SimpleForm>
  </Create>
);
