import React from 'react';
import moment from 'moment';
import {
  Box,
  Toolbar,
} from '@material-ui/core';
import {
    List,
    Datagrid,
    Edit,
    Create,
    Filter,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput,
    DateTimeInput,
    BooleanInput,
    FileInput,
    ImageField,
    EditButton,
    CreateButton,
    CloneButton,
    required,
    maxLength,
    minValue,
    FunctionField,
    BooleanField,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput, FormDataConsumer,
} from 'react-admin';
import { EventStatus } from '../constants/EventTypes';

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Гарчиг"
      source="title"
      alwaysOn
    />
    <NumberInput
      label="Үнэ"
      source="price"
      alwaysOn
    />
    <DateTimeInput
      label="Эхлэх хугацаа"
      source="startDate"
      alwaysOn
    />
    <DateTimeInput
      label="Дуусах хугацаа"
      source="endDate"
      alwaysOn
    />
    <SelectInput
      label="Төлөв"
      emptyText="Бүгд"
      source="status"
      choices={EventStatus}
      alwaysOn
    />
    <SelectInput
      label="Санал болгох?"
      emptyText="Бүгд"
      source="isRecommended"
      choices={[
        { id: 'true', name: 'Тийм' },
        { id: 'false', name: 'Үгүй' },
      ]}
      alwaysOn
    />
  </Filter>
);

const EventActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getUsersCount = record => `${record.participantNumber} / ${record.maxParticipant}`;
const getFormattedDate = date => moment(date).format('MM-DD-YYYY, HH:mm:ss A');
const getStatus = status => EventStatus.find(item => item.id === status).name;
const getMentorName = mentor => `${mentor?.id}. ${mentor?.user?.firstName} ${mentor?.user?.lastName}`;

export const EventList = props => (
  <List
    {...props}
    filters={<EventFilter />}
    actions={<EventActions props={props} />}
  >
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Гарчиг" source="title" />
      <TextField label="Үнэ" source="price" />
      <FunctionField label="Эхлэх хугацаа" render={record => getFormattedDate(record.startDate)} />
      <FunctionField label="Дуусах хугацаа" render={record => getFormattedDate(record.endDate)} />
      <FunctionField label="Оролцсон тоо" render={record => getUsersCount(record)} />
      <FunctionField label="Төлөв" render={record => getStatus(record.status)} />
      <BooleanField label="Санал болгох?" source="isRecommended" />
      <EditButton />
    </Datagrid>
  </List>
);

const EventForm = (props) => {
  return (
    <>
      <Box minWidth={500} maxWidth={500}>
        <BooleanInput label="Published notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
        <BooleanInput
          label="Санал болгох"
          source="isRecommended"
        />
        <TextInput
          label="Гарчиг"
          source="title"
          validate={[
            required(),
            maxLength(255),
          ]}
          fullWidth
        />
        <TextInput
          label="Тайлбар"
          source="description"
          rows={8}
          multiline
          fullWidth
        />
        <NumberInput
          label="Үнэ"
          source="price"
          validate={[
            required(),
            minValue(0),
          ]}
          fullWidth
        />
        <ImageField source="thumbnail" title="Зураг" />
        <FileInput
          label="Зураг"
          source="file"
          accept="image/*"
        >
          <ImageField
            title="image"
            source="thumbnail"
          />
        </FileInput>
        <TextInput
          label="Байршил"
          source="location"
          validate={[maxLength(255)]}
          fullWidth
        />
        <NumberInput
          label="Орох хүний тоо"
          source="maxParticipant"
          validate={[
            required(),
            minValue(1),
          ]}
          fullWidth
        />
        <DateTimeInput
          label="Эхлэх хугацаа"
          source="startDate"
          fullWidth
        />
        <DateTimeInput
          label="Дуусах хугацаа"
          source="endDate"
          fullWidth
        />
        <SelectInput
          fullWidth
          source="status"
          label="Төлөв"
          choices={EventStatus}
        />
        <BooleanInput
          label="Google Meeting үүсгэх"
          source="hasMeeting"
          defaultValue={false}
        />
        <ReferenceArrayInput label="Чиглэл(tag)" source="tagging.majorFieldIds" reference="majorFields" fullWidth>
          <SelectArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceInput label="Зохион байгуулагч" source="organizerId" reference="mentors" fullWidth perPage={150}>
          <AutocompleteInput optionText={getMentorName} />
        </ReferenceInput>
      </Box>
    </>
  )
}

const EventTitle = ({ record }) => <span>Event {record ? `"${record.title}"` : ''}</span>;

export const EventEdit = props => {
  return (
    <Edit title={<EventTitle />} {...props}>
      <SimpleForm>
        <CloneButton />
        <TextField
          label="ID"
          source="id"
        />
        <BooleanInput label="Өөрчлөлт оруулсан notification явуулах уу?" source="sendUpdatedNotification" initialValue={false}/>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
              formData.sendUpdatedNotification && <TextInput fullWidth label="Өөрчлөлтийн товч тайлбар" source="notificationText" validate={required()} />
          }
        </FormDataConsumer>
        <EventForm />
        <TextField
          label="Google Meeting URL"
          source="meetingURL"
        />
      </SimpleForm>
    </Edit>
  )
}

export const EventCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <EventForm />
      </SimpleForm>
    </Create>
  )
}
