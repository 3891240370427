export const ADMIN_ROLES = [
  { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN' },
  { id: 'MENTOR_ADMIN', name: 'MENTOR_ADMIN' },
  { id: 'SALES_ADMIN', name: 'SALES_ADMIN' },
  { id: 'TEST_ADMIN', name: 'TEST_ADMIN' },
  { id: 'PRODUCTION_ADMIN', name: 'PRODUCTION_ADMIN' },
  { id: 'GAME_ADMIN', name: 'GAME_ADMIN' },
  { id: 'DATABASE_ADMIN', name: 'DATABASE_ADMIN' },
  { id: 'ADMIN_MENTOR_ROLE', name: 'ADMIN_MENTOR_ROLE' },
];

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  MENTOR_ADMIN: 'MENTOR_ADMIN',
  SALES_ADMIN: 'SALES_ADMIN',
  TEST_ADMIN: 'TEST_ADMIN',
  PRODUCTION_ADMIN: 'PRODUCTION_ADMIN',
  GAME_ADMIN: 'GAME_ADMIN',
  DATABASE_ADMIN: 'DATABASE_ADMIN',
  ADMIN_MENTOR_ROLE: 'ADMIN_MENTOR_ROLE',
};
