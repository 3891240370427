export const COURSE_PROVIDER_CONTENT_TYPES = [
  { id: 'I1', name: 'I1' },
  { id: 'I2', name: 'I2' },
  { id: 'I3', name: 'I3' },
  { id: 'I4', name: 'I4' },
  { id: 'I5', name: 'I5' },
  { id: 'I6', name: 'I6' },
  { id: 'O1', name: 'O1' },
  { id: 'O2', name: 'O2' },
  { id: 'O3', name: 'O3' },
  { id: 'O4', name: 'O4' },
  { id: 'O5', name: 'O5' },
  { id: 'O6', name: 'O6' },
];
