import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin';

const CommentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Мессэж" source="message" alwaysOn />
  </Filter>
);

export const CommentList = props => (
  <List {...props} filters={<CommentFilter/>}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Message" source="message" />
    </Datagrid>
  </List>
);
