import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  CreateButton,
  ExportButton,
  SimpleForm,
  CloneButton,
  SelectInput,
  NumberInput,
  DateTimeInput,
  required,
} from 'react-admin';
import { BANK_TYPES, AMOUNT_TYPES } from '../constants/BankTransaction';

const TrxFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Харьцсан данс" source="relatedAccountNo" alwaysOn />
    <TextInput label="Гүйлгээний утга" source="description" alwaysOn />
    <TextInput label="Гүйлгээний төрөл" source="bankType" alwaysOn />
  </Filter>
);

const TransactionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

const CountryTitle = ({ record }) => {
  const { code } = record;
  return <span>Transaction {record ? `"${code}"` : ''}</span>;
};

export const TransactionEdit = props => (
  <Edit title={<CountryTitle />} {...props}>
    <SimpleForm>
      <SelectInput
          label="Банк"
          source="bankType"
          choices={BANK_TYPES}
          validate={required()}
      />
      <TextInput label="Дансны дугаар" source="accountNo" validate={required()}/>
      <TextInput label="Гүйлгээний ID" source="txnJournalNo" validate={required()} />
      <NumberInput label="Гүйлгээний дүн" source="amount" validate={required()} />
      <SelectInput
          label="Гүйлгээний төрөл"
          source="amountType"
          choices={AMOUNT_TYPES}
          validate={required()}
      />
      <TextInput label="Гүйлгээний утга" source="description" validate={required()} />
      <DateTimeInput label="Гүйлгээ хийгдсэн огноо" source="txnDate" validate={required()} />
      <TextInput label="Харьцсан данс" source="relatedAccountNo" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const TransactionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
          label="Банк"
          source="bankType"
          choices={BANK_TYPES}
          validate={required()}
      />
      <TextInput label="Дансны дугаар" source="accountNo" validate={required()} />
      <TextInput label="Гүйлгээний ID" source="txnJournalNo" validate={required()} />
      <NumberInput label="Гүйлгээний дүн" source="amount" validate={required()} />
      <SelectInput
          label="Гүйлгээний төрөл"
          source="amountType"
          choices={AMOUNT_TYPES}
          validate={required()}
      />
      <TextInput label="Гүйлгээний утга" source="description" validate={required()} />
      <DateTimeInput label="Гүйлгээ хийгдсэн огноо" source="txnDate" validate={required()} />
      <TextInput label="Харьцсан данс" source="relatedAccountNo" validate={required()} />
    </SimpleForm>
  </Create>
);

export const TransactionList = props => (
  <List {...props} filters={<TrxFilter />} bulkActionButtons={false} actions={<TransactionActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Record ID" source="recordId" />
      <TextField label="Гүйлгээний ID" source="txnJournalNo" />
      <TextField label="Банк" source="bankType" />
      <TextField label="Гүйлгээний утга" source="description" />
      <TextField label="Гүйлгээний дүн" source="amount" />
      <TextField label="Гүйлгээний хийгдсэн огноо" source="txnDate" />
      <TextField label="Харьцсан данс" source="relatedAccountNo" />
      <TextField label="Yүссэн огноо" source="createdAt" />
      <CloneButton />
    </Datagrid>
  </List>
);
