import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
} from 'react-admin';

export const DeploymentConfigList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Name" source="name" />
      <TextField label="Value" source="value" />
      <EditButton />
    </Datagrid>
  </List>
);

export const DeploymentConfigEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput
        onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
        label="Name"
        source="name"
      />
      <TextInput label="Value" source="value" />
    </SimpleForm>
  </Edit>
);

export const DeploymentConfigCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
        label="Name"
        source="name"
      />
      <TextInput label="Value" source="value" />
    </SimpleForm>
  </Create>
);
