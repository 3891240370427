import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  Edit,
  EditButton,
} from 'react-admin';

export const GameQuizList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="Quiz title" source="title" />
      <ReferenceField source="tgCategoryId" reference="gamingCategories">
        <TextField label="Category name" source="name" />
      </ReferenceField>
      <TextField label="Дараалан зөв хариулах шагнал(diamond)" source="streakRecordCost" />
      <TextField label="Асуулт бүрд зөв хариулах xp" source="xpPerQuestion" />
      <EditButton />
    </Datagrid>
  </List>
);

const GameQuizTitle = ({ record }) => {
  const { id } = record;
  return <span>GameQuiz {record ? `"${id}"` : ''}</span>;
};

export const GameQuizEdit = props => (
  <Edit title={<GameQuizTitle />} {...props}>
    <SimpleForm>
      <SelectInput optionText="title" optionValue="id" />
      <ReferenceInput label="Тоглоомын төрөл" source="tgCategoryId" reference="gamingCategories">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput label="Дараалсан асуултын шагнал" source="streakRecordCost" pattern="[0-9]{1,2}" />
      <TextInput label="Асуулт бүрд зөв хариулах xp" source="xpPerQuestion" pattern="[0-9]{1,3}" />
      <ReferenceArrayInput label="Tags" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const GameQuizCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput optionText="title" optionValue="id" />
      <ReferenceInput label="Тоглоомын төрөл" source="tgCategoryId" reference="gamingCategories">
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput label="Дараалсан асуултын шагнал" source="streakRecordCost" pattern="[0-9]{1,2}" />
      <TextInput label="Асуулт бүрд зөв хариулах xp" source="xpPerQuestion" pattern="[0-9]{1,3}" />
      <ReferenceArrayInput label="Tags" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
