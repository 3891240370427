import React from 'react';
import {
  Box,
  Toolbar,
} from '@material-ui/core';
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  FunctionField,
  SimpleForm,
  EditButton,
  CreateButton,
  Filter,
  required,
  maxLength,
} from 'react-admin';
import {
  QuickInformationTypes,
} from '../constants/QuickInformation';

const QuickInformationFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Төрөл"
      emptyText="Бүгд"
      source="type"
      choices={QuickInformationTypes}
      fullWidth
      alwaysOn
    />
  </Filter>
);

const QuickInformationActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getTypeById = (id) => QuickInformationTypes.find(type => type.id === id).name;

export const QuickInformationList = props => (
  <List
    {...props}
    filters={<QuickInformationFilter />}
    actions={<QuickInformationActions props={props} />}
  >
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Мэдээлэл" source="body" />
      <FunctionField label="Төрөл" render={record => getTypeById(record.type)} />
      <EditButton />
    </Datagrid>
  </List>
);

const QuickInformationForm = () => (
  <Box minWidth="25%" maxWidth="25%">
    <TextInput
      label="Мэдээлэл"
      source="body"
      multiline
      fullWidth
      rows={4}
      validate={[
        required(),
        maxLength(255),
      ]}
    />
    <SelectInput
      fullWidth
      source="type"
      label="Төрөл"
      helperText="Апп дээр хаана харагдах"
      choices={QuickInformationTypes}
      validate={[required()]}
    />
  </Box>
)

export const QuickInformationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField label="ID" source="id" />
      <QuickInformationForm />
    </SimpleForm>
  </Edit>
);

export const QuickInformationCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <QuickInformationForm />
    </SimpleForm>
  </Create>
);
