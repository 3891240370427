import React, { useState } from 'react';
import { useNotify, useDataProvider, Button } from 'react-admin';

const ActivateButton = ({ record, activate }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dataProvider.patch(`1k-promo/codes/${record.code}/${activate ? 'activate' : 'deactivate'}`, {})
        .then(() => {
            // We have to call get list in order to refresh list data
            dataProvider.getList('1k-promo/codes', { 
              pagination:{ 
                page: 1, perPage: 10,
              }, 
              sort: {
                field: "id",
                order: "ASC",
              } 
            }).then(response => {
              notify(`Code ${activate ? 'activated' : 'deactivated'}`);
          });
        })
        .catch((e) => {
          console.log(e)
            notify('Error: code not activated', 'warning')
        })
        .finally(() => {
            setLoading(false);
        });
    };
    return <Button label={`${activate ? 'Activate' : 'Deactivate'}`} onClick={handleClick} disabled={loading} />;
};

export default ActivateButton;