import React from 'react';
import {
  Box,
  Toolbar,
} from '@material-ui/core';
import {
  List,
  Datagrid,
  Edit,
  Create,
  Filter,
  SimpleForm,
  TextField,
  BooleanInput,
  EditButton,
  CreateButton,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

const ParticipantStatus = [
  { id: true, name: 'Баталгаажсан' },
  { id: false, name: 'Баталгаажаагүй' },
];

const ParticipantFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Эвент"
      emptyText="Бүгд"
      source="eventId"
      reference="events"
      alwaysOn
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput
      label="Хэрэглэгч"
      emptyText="Бүгд"
      source="userId"
      reference="users"
      alwaysOn
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <SelectInput
      label="Төлөв"
      emptyText="Бүгд"
      source="isVerified"
      choices={ParticipantStatus}
      alwaysOn
    />
  </Filter>
);

const ParticipantActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getParticipantStatus = (isVerified) => ParticipantStatus.find(status => status.id === isVerified).name;

export const ParticipantList = props => (
  <List
    {...props}
    filters={<ParticipantFilter />}
    actions={<ParticipantActions props={props} />}
  >
    <Datagrid optimized>
      <TextField
        label="ID"
        source="id"
      />
      <ReferenceField
        label="Эвент"
        source="event.id"
        reference="events"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField
        label="Хэрэглэгч"
        source="participant.id"
        reference="users"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField
        label="Тасалбар"
        source="ticketCode"
      />
      <FunctionField
        label="Төлөв"
        render={record => getParticipantStatus(record.isVerified)}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const ParticipantTitle = ({ record }) => <span>Participant {record ? `"${record.title}"` : ''}</span>;

export const ParticipantEdit = props => (
  <Edit title={<ParticipantTitle />} {...props}>
    <SimpleForm>
      <TextField
        label="ID"
        source="id"
      />
      <ReferenceField
        label="Эвент"
        source="event.id"
        reference="events"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField
        label="Хэрэглэгч"
        source="participant.id"
        reference="users"
      >
        <TextField source="email" />
      </ReferenceField>
      <Box mt={3}>
        <BooleanInput
          label="Батаалгаажуулах"
          source="isVerified"
        />
      </Box>
    </SimpleForm>
  </Edit>
);


export const ParticipantCreate = props => (
  <Create {...props}>
    <SimpleForm>
    </SimpleForm>
  </Create>
);
