import React from 'react';
import { QuestionTypes } from '../constants/Question';
import { CreateButton, ExportButton } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import ImportButton from './ImportButton';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  SimpleForm,
  FileInput,
  ImageField,
  FileField,
  useDataProvider,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  Create,
  ReferenceArrayInput,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  Filter,
  required,
  AutocompleteInput,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput label="Tag" source="languageTag" reference="tags" perPage={500} alwaysOn>
      <AutocompleteArrayInput
        optionText="name"
        optionValue="name"
        shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
        options={{ suggestionsContainerProps: { disablePortal: true } }}
      />
    </ReferenceArrayInput>
  </Filter>
);

const QuestionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    basePath,
    exporter,
  } = props;
  const dataProvider = useDataProvider();

  const onParseData = data => {
    dataProvider.importCSV('questions', { data: Object.values(data) });
  };

  return (
    <Toolbar>
      {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton onParseData={onParseData} />
    </Toolbar>
  );
};
export const QuestionList = props => (
  <List {...props}  filters={<TextFilter/>} actions={<QuestionActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Type" source="type" />
      <TextField label="Асуулт" source="txt" />
      <ArrayField source="choices">
        <Datagrid>
          <TextField label="Хариулт" source="txt" />
        </Datagrid>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);
const QuestionTitle = ({ record }) => {
  const { id } = record;
  return <span>Question {record ? `"${id}"` : ''}</span>;
};

export const QuestionEdit = props => (
  <Edit title={<QuestionTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Тест" source="quizId" reference="quizzes" perPage={500}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <SelectInput source="type" choices={QuestionTypes} />
      <TextInput label="Асуулт" source="txt" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTag" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        label="Тайлбар"
        source="explanationTag"
        reference="questionExplanations">
        <SelectInput optionText="tag" optionValue="tag" />
      </ReferenceInput>
      <ImageField source="photo" label="Current photo" />
      <FileInput source="file" accept="image/png">
        <FileField source="photo" />
      </FileInput>
      <ArrayInput source="choices">
        <SimpleFormIterator>
          <TextInput label="Хариулт" source="txt" />
          <BooleanInput
            label="Зөв хариулт эсэх"
            source="correctAnswer"
            default={false}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const QuestionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={QuestionTypes} />
      <ReferenceInput label="Тест" source="quizId" reference="quizzes" perPage={500}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <TextInput label="Асуулт" source="txt" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTag" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        label="Тайлбар"
        source="explanationTag"
        reference="questionExplanations">
        <SelectInput optionText="tag" optionValue="tag" />
      </ReferenceInput>
      <ArrayInput source="choices">
        <SimpleFormIterator>
          <TextInput label="Хариулт" source="txt" />
          <BooleanInput label="Зөв хариулт эсэх" source="correctAnswer" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
