
import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Create,
  TextInput,
  SimpleForm,
} from 'react-admin';

export const GameLevelInstructionList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="Эхлэх xp" source="start" />
      <TextField label="Дуусах xp" source="end" />
      <TextField label="Түвшин" source="level" />
      <TextField label="Олох diamond" source="diamondPrize" />
    </Datagrid>
  </List>
);

export const GameLevelInstructionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Эхлэх xp" source="start" pattern="[0-9]{1,}" />
      <TextInput label="Дуусах xp" source="end" pattern="[0-9]{1,}" />
      <TextInput label="Түвшин" source="level" pattern="[0-9]{1,}" />
      <TextInput label="Олох diamond" source="diamondPrize" pattern="[0-9]{1,}" />
    </SimpleForm>
  </Create>
);
