import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ExportButton,
  Filter,
  EditButton,
  CreateButton,
  required,
  minLength,
  maxLength,
} from 'react-admin';
const CodeFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Code" source="code" alwaysOn />
  </Filter>
);

const LanguageActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const LanguageList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<LanguageActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Код" source="code" />
      <TextField label="Нэр" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

const LanguageTitle = ({ record }) => {
  const { code } = record;
  return <span>Language {record ? `"${code}"` : ''}</span>;
};

export const LanguageEdit = props => (
  <Edit title={<LanguageTitle />} {...props}>
    <SimpleForm>
      <TextField label="ID" source="id" />
      <TextInput label="Код" source="code" validate={[required(), minLength(2), maxLength(4)]}/>
      <TextInput label="Нэр" source="name" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export const LanguageCreate = props => (
  <Create {...props}>
    <SimpleForm>
        <TextField label="ID" source="id" />
      <TextInput label="Код" source="code" validate={[required(), minLength(2), maxLength(4)]}/>
      <TextInput label="Нэр" source="name" validate={[required()]} />
    </SimpleForm>
  </Create>
);