import decodeJwt from 'jwt-decode';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${backendUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ jwToken, user }) => {
        const decodedToken = decodeJwt(jwToken);
        localStorage.setItem('token', jwToken);
        localStorage.setItem('username', user.email);
        localStorage.setItem('roles', decodedToken.roles);
      });
  },
  logout: () => {
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      authProvider.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const roles =  localStorage.getItem('roles');
    return roles ? Promise.resolve(roles) : Promise.reject();
  }
};

export default authProvider;
