import React, {useEffect, useState} from 'react';
import {MENTOR_TOPICS} from '../constants/AskingMentor';
import Toolbar from '@material-ui/core/Toolbar';
import {CreateButton, ReferenceArrayInput, SelectArrayInput} from 'react-admin';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    ReferenceField,
    TextInput,
    EditButton,
    ReferenceInput,
    SelectInput,
    required,
    minLength,
    SimpleFormIterator,
    ArrayInput,
    FileField,
    FileInput,
    AutocompleteInput,
    FunctionField,
    Filter,
    NumberInput,
    BooleanInput,
    FormDataConsumer,
} from 'react-admin';
import {COUNTRY_NAMES} from '../constants';
import VerifiedMentorToggleBtn from './VerifiedMentorToggleBtn';
import dataProvider from "../utils/dataProvider";

const validateRequired = [required(), minLength(3)];

const getStatus = (status) => {
    if (status === 'draft') {
        return "драфтласан";
    }
    return "нийтлэсэн";
}

const TextFilter = (props) => (
    <Filter {...props}>
        <NumberInput label="userId" source="userId" alwaysOn/>
        <TextInput label="Сургууль" source="university" alwaysOn/>
    </Filter>
);

const iterateChoices = (choices, list, parentId, level) => {
    for (let id in list[parentId]) {
        const item = list[parentId][id];
        choices.push({
            id: item.id,
            name: '_'.repeat(level * 4) + item.name,
        });
        iterateChoices(choices, list, id, level + 1);
    }
    return choices;
}
const buildChoices = (list) => {
    let map = {};
    for (let j of list) {
        if (!(j.parentId in map)) {
            map[j.parentId] = {};
        }
        map[j.parentId][j.id] = j;
    }
    return iterateChoices([], map, null, 0);
}

const fetchData = async () => {
    const majorFieldsData = await dataProvider.getList('majorFields', {
        "pagination": {
            "page": 1,
            "perPage": 500
        },
        "sort": {
            "field": "parentId",
            "order": "DESC"
        },
    });
    const choices = buildChoices(majorFieldsData.data);
    return choices;
}

const UserAction = props => {
    const {
        filters,
        showFilter,
        resource,
        displayedFilters,
        filterValues,
        basePath,
    } = props

    return (
        <Toolbar>
            {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
        </Toolbar>
    );
};

export const MentorList = props => (
    <List {...props} filters={<TextFilter/>} actions={<UserAction props={props}/>}>
        <Datagrid optimized>
            <TextField label="ID" source="id"/>
            <ReferenceField source="userId" reference="users">
                <TextField label="Нэр" source="firstName"/>
            </ReferenceField>
            <TextField label="Сургууль" source="university"/>
            <FunctionField label="Статус" render={record => getStatus(record.status)}/>
            <VerifiedMentorToggleBtn label="Verified?"/>
            <EditButton/>
        </Datagrid>
    </List>
);

const MentorTitle = ({record}) => {
    const {id} = record;
    return <span>Mentor {record ? `"${id}"` : ''}</span>;
};

export const MentorEdit = props => {
    const [majorFieldChoices, setMajorFieldChoices] = useState();

    useEffect(() => {
        fetchData().then(choices => setMajorFieldChoices(choices));
    }, [props])
    return (
        <Edit title={<MentorTitle/>} {...props}>
            <SimpleForm>
                <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.shouldSendNotification && <TextInput label="Сонордуулгын текст" source="notificationText" validate={required()} />
                    }
                </FormDataConsumer>
                <TextInput disabled source="id"/>
                <ReferenceInput source="userId" reference="users">
                    <AutocompleteInput optionText={optionRenderer} options={{ suggestionsContainerProps: { disablePortal: true } }} />
                </ReferenceInput>
                <TextInput multiline fullWidth rows={8} label="Намтар" source="bio"/>
                <TextInput
                    label="Сургууль"
                    source="university"
                    validate={validateRequired}
                />
                <SelectInput
                    label="Улс"
                    source="country"
                    choices={COUNTRY_NAMES}
                />
                <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages"
                                validate={[required()]}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" validate={[required()]}
                                     reference="countries">
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>
                <TextInput
                    label="Боловсролын зэрэг"
                    source="degree"
                    validate={validateRequired}
                />
                <SelectArrayInput source="tagging.majorFieldIds" label="Чиглэл (tag)" validate={[required()]}
                                  choices={majorFieldChoices}/>
                <TextInput
                    label="Мэргэжил"
                    source="profession"
                    validate={validateRequired}
                />
                <TextInput label="Сошэйл медиа" source="social"/>
                <ArrayInput source="mentoringTopics">
                    <SimpleFormIterator>
                        <SelectInput choices={MENTOR_TOPICS}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
                    <FileField source="video" title="video"/>
                </FileInput>
                <SelectInput
                    label="статус"
                    source="status"
                    choices={[
                        {id: 'draft', name: 'драфтласан'},
                        {id: 'published', name: 'нийтлэсэн'},
                    ]}
                />
            </SimpleForm>
        </Edit>
    )
};

const optionRenderer = choice => `${choice.email} ${choice.social}`;

export const MentorCreate = props => {
    const [majorFieldChoices, setMajorFieldChoices] = useState();

    useEffect(() => {
        fetchData().then(choices => setMajorFieldChoices(choices));
    }, [props])
    return (
        <Create {...props}>
            <SimpleForm>
                <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.shouldSendNotification && <TextInput label="Сонордуулгын текст" source="notificationText" validate={required()} />
                    }
                </FormDataConsumer>
                <ReferenceInput source="userId" reference="users">
                    <AutocompleteInput optionText={optionRenderer} options={{ suggestionsContainerProps: { disablePortal: true } }} />
                </ReferenceInput>
                <TextInput multiline fullWidth rows={8} label="Намтар" source="bio"/>
                <TextInput
                    label="Сургууль"
                    source="university"
                    validate={validateRequired}
                />
                <SelectInput
                    label="Улс"
                    source="country"
                    choices={COUNTRY_NAMES}
                />
                <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages"
                                validate={[required()]}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" validate={[required()]}
                                     reference="countries">
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>
                <SelectArrayInput source="tagging.majorFieldIds" label="Чиглэл (tag)" validate={[required()]}
                                  choices={majorFieldChoices}/>
                <TextInput
                    label="Боловсролын зэрэг"
                    source="degree"
                    validate={validateRequired}
                />
                <TextInput
                    label="Мэргэжил"
                    source="profession"
                    validate={validateRequired}
                />
                <TextInput label="Сошэйл медиа" source="social"/>
                <ArrayInput source="mentoringTopics">
                    <SimpleFormIterator>
                        <SelectInput choices={MENTOR_TOPICS}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
                    <FileField source="video" title="video"/>
                </FileInput>
                <SelectInput
                    label="статус"
                    source="status"
                    choices={[
                        {id: 'draft', name: 'драфтласан'},
                        {id: 'published', name: 'нийтлэсэн'},
                    ]}
                />
            </SimpleForm>
        </Create>
    )
};
