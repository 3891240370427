import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FunctionField,
  SelectInput,
  BooleanInput,
  BooleanField,
  Filter,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import VideoJS from './VideoJS';

const getStatus = (videoId) => {
  if (videoId === null) {
    return "Processing";
  }
  return "Finished";
}

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Гарчиг" source="title" alwaysOn />
    <TextInput label="Хичээлийн ID" source="courseId" />
  </Filter>
);

const CourseSectionAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const CourseSectionList = props => (
  <List {...props} filters={<TextFilter />} actions={<CourseSectionAction props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField source="courseId" reference="courses">
        <TextField label="Хичээлийн нэр" source="topic" />
      </ReferenceField>
      <TextField label="Нэр" source="title" />
      <TextField label="Дараалал" source="order" />
      <TextField label="Хичээлийн төрөл" source="contentType" />
      <TextField label="Хугацаа" source="videoDuration" />
      <FunctionField label="Статус" render={record => getStatus(record.videoId)} />
      <BooleanField label="Үнэгүй үзэх" source="isPreview" />
      <EditButton />
    </Datagrid>
  </List>
);

const CourseSectionTitle = ({ record }) => {
  const { id } = record;
  return <span>Course Section {record ? `"${id}"` : ''}</span>;
};

const EmptyPanel = ({ label }) => (
  <Box
    width={500}
    px={2}
    py={5}
    borderRadius={5}
    border={1}
    borderColor="#dddddd"
    bgcolor="#f5f5f5"
    color="#aaaaaa"
    textAlign="center"
    fontSize={12}
  >{label}</Box>
)

export const CourseSectionEdit = props => (
  <Edit title={<CourseSectionTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput fullWidth source="courseId" reference="courses" perPage={100}>
        <AutocompleteInput optionText="topic" />
      </ReferenceInput>
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Нэр" source="title" />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <SelectInput
        label="Хичээлийн төрөл"
        source="contentType"
        choices={[
          { id: 'video', name: 'Видео' },
          { id: 'text', name: 'Текст' },
          { id: 'mix', name: 'Холимог' },
        ]}
      />
      <ReferenceInput
        fullWidth
        label="Mini Test"
        source="quizId"
        reference="quizzes"
        perPage={100}>
        <AutocompleteInput fullWidth optionText="title" optionValue="id" options={{ suggestionsContainerProps: { disablePortal: true } }} />
      </ReferenceInput>
      <ImageField source="thumbnailImage" label="Зураг" />
      <FileInput source="imgFile" label="Зураг" accept="image/*">
        <FileField source="img" title="image" />
      </FileInput>
      <TextInput label="Хугацаа" source="videoDuration" />
      <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
      <FunctionField
        label="Видео"
        render={record => record.videoId ? (
          <VideoJS videoId={record.videoId} />
        ) : (
          <EmptyPanel label="Хов хоосон! 😥 😥 😥" />
        )}
      />
    </SimpleForm>
  </Edit>
);

export const CourseSectionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput fullWidth source="courseId" reference="courses" perPage={100}>
        <AutocompleteInput optionText="topic" />
      </ReferenceInput>
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Нэр" source="title" />
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <SelectInput
        label="Хичээлийн төрөл"
        source="contentType"
        choices={[
          { id: 'VIDEO', name: 'Видео' },
          { id: 'TEXT', name: 'Текст' },
          { id: 'MIX', name: 'Холимог' },
        ]}
      />
      <ReferenceInput
        fullWidth
        label="Mini Test"
        source="quizId"
        reference="quizzes"
        perPage={100}>
        <AutocompleteInput fullWidth optionText="title" optionValue="id" options={{ suggestionsContainerProps: { disablePortal: true } }} />
      </ReferenceInput>
      <FileInput source="imgFile" label="Зураг" accept="image/*">
        <FileField source="img" title="image" />
      </FileInput>
      <TextInput label="Хугацаа" source="videoDuration" />
      <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
    </SimpleForm>
  </Create>
);
