import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import RichTextInput from 'ra-input-rich-text';
import {
  List,
  Datagrid,
  Create,
  CreateButton,
  Edit,
  Filter,
  ImageField,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
  SelectInput,
  required
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="name" alwaysOn />
  </Filter>
);


const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

export const CategoryList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Нэр" source="name" />
      <ImageField label="Зураг" source="image" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const CategoryTitle = ({ record }) => {
  const { name } = record;
  return <span>Category {record ? `"${name}"` : ''}</span>;
};

export const CategoryEdit = props => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Нэр" source="name" />
      <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <ImageField source="image" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Нэр" source="name" />
      <RichTextInput label="Танилцуулга" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Create>
);
