export const CONTENT_TYPES = [
  { id: 'course', name: 'course' },
  { id: 'mentor', name: 'mentor' },
  { id: 'university', name: 'university' },
  { id: 'scholarship', name: 'scholarship' },
  { id: 'quizBundle', name: 'quizBundle' },
  { id: 'quiz', name: 'quiz' },
  { id: 'post', name: 'post' },
  { id: 'mentorQnA', name: 'mentorQnA' },
  { id: 'live_streaming', name: 'live_streaming' },
];
