import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  ImageField,
  FileInput,
  FileField,
  required,
  SelectInput,
  FunctionField,
  DateField,
  CloneButton,
} from 'react-admin';

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

export const LiveStreamingScheduleList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Гарчиг" source="title" />
      <DateField label="Эхлэх" source="startAt" showTime />
      <DateField label="Дуусах" source="endAt" showTime />
      <FunctionField label="Streaming URL" render={record => (<a href={record.streamingUrl}>{record.streamingUrl}</a>)} />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const Title = ({ record }) => {
  const { title } = record;
  return <span>Live Streaming хуваарь {record ? `"${title}"` : ''}</span>;
};

export const LiveStreamingScheduleEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <CloneButton />
      <ReferenceInput source="creatorId" reference="users">
        <AutocompleteInput
          optionText={item => (item.email ? item.email : '')}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput label="Гарчиг" fullWidth source="title"  validate={[required()]} />
      <TextInput multiline fullWidth rows={8} label="Тайлбар" source="description" helperText="Дэлгэрэнгүй мэдээлэл" />
      <DateTimeInput label="Эхлэх" source="startAt" showTime  validate={[required()]} />
      <DateTimeInput label="Дуусах" source="endAt" showTime  validate={[required()]} />
      <TextInput label="Streaming URL" fullWidth source="streamingUrl" />
      <TextInput label="Replay Streaming URL" fullWidth source="replayStreamingUrl" />
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="imgFile" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const LiveStreamingScheduleCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="creatorId" reference="users">
        <AutocompleteInput
          optionText={item => (item.email ? item.email : '')}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput label="Гарчиг" fullWidth source="title"  validate={[required()]} />
      <TextInput multiline fullWidth rows={8} label="Тайлбар" source="description" helperText="Дэлгэрэнгүй мэдээлэл" />
      <DateTimeInput label="Эхлэх" source="startAt" showTime  validate={[required()]} />
      <DateTimeInput label="Дуусах" source="endAt" showTime  validate={[required()]} />
      <TextInput label="Streaming URL" fullWidth source="streamingUrl" />
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="imgFile" label="Зураг" accept="image/*"  validate={[required()]}>
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
