import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
  ReferenceArrayInput,
  SelectArrayInput,
  Filter,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="University Name" source="name" alwaysOn />
  </Filter>
);

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const UniversityList = (props) => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Их сургуулийн нэр" source="name" />
      <TextField label="Байршил" source="location" />
      <TextField source="tags" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UniversityEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <TextInput fullWidth multiline source="picture" />
      <TextInput multiline fullWidth rows={3} source="name" />
      <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" reference="countries" validate={[required()]}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <TextInput multiline fullWidth rows={3} source="location" />
      <TextInput multiline fullWidth rows={8} source="description" />
      <RichTextInput source="requirement" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <TextInput fullWidth source="link" />
    </SimpleForm>
  </Edit>
);

export const UniversityCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} />
      <TextInput disabled source="id" />
      <TextInput fullWidth multiline source="picture" />
      <TextInput multiline fullWidth rows={3} source="name" />
      <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" reference="countries" validate={[required()]}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="tags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <TextInput multiline fullWidth rows={3} source="location" />
      <TextInput multiline fullWidth rows={8} source="description" />
      <RichTextInput source="requirement" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <TextInput fullWidth source="link" />
    </SimpleForm>
  </Create>
);
