import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin';

export const ExplanationList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Tag" source="tag" />
      <TextField label="Тайлбар" source="text" />
      <EditButton />
    </Datagrid>
  </List>
);

const ExplanationTitle = ({ record }) => {
  const { id } = record;
  return <span>QuestionExplanation {record ? `"${id}"` : ''}</span>;
};

export const ExplanationEdit = props => (
  <Edit title={<ExplanationTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Tag" source="tag" />
      <TextInput label="Тайлбар" source="text" />
    </SimpleForm>
  </Edit>
);

export const ExplanationCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Tag" source="tag" />
      <TextInput label="Тайлбар" source="text" />
    </SimpleForm>
  </Create>
);
