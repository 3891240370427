import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

export const UserCount = (props) => {
  const [paidUsersCount, setCount] = useState(null);
  const dataProvider = useDataProvider();
  
  useEffect(() =>{
    dataProvider.get('status/paid-users').then((res) => {setCount(res.data)})
  }, [dataProvider])

  return(
    <React.Fragment>
      <div style={{flex: 1, backgroundColor: '#fff'}}>
         <h3 style={{fontSize: 40, paddingLeft: 50, paddingTop: 50}}>Нийт хэрэглэгчид = {paidUsersCount? paidUsersCount - 74  : 0}</h3>
          <p style={{color: 'gray', fontSize: 14, paddingLeft: 50}}>(ECC, ментор, болон өөрсдийн тоог хассан болно)</p>
      </div>
    </React.Fragment>
);}
