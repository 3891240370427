import React from 'react';
import { Toolbar } from '@material-ui/core';
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  ReferenceField,
  CreateButton,
  Filter,
  FunctionField,
} from 'react-admin';
import RejectPurchaseOrderBtnForEvent from './skills/RejectPurchaseOrderBtn';

const PurchaseOrderForEventFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Эвент"
      emptyText="Бүгд"
      source="productId"
      reference="events"
      alwaysOn
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput
      label="Хэрэглэгч"
      emptyText="Бүгд"
      source="userId"
      reference="users"
      alwaysOn
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <TextInput
      label="Төлсөн дүн"
      source="amount"
      alwaysOn
    />
  </Filter>
);

const renderRejectButton = (status, id) => {
  if (status !== 'APPROVED') {
    return '';
  }

  return <RejectPurchaseOrderBtnForEvent id={id} />;
}

const PurchaseOrderForEventActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const PurchaseOrderListForEvent = props => (
  <List
    {...props}
    filters={<PurchaseOrderForEventFilter />}
    actions={<PurchaseOrderForEventActions props={props} />}
  >
    <Datagrid optimized>
      <TextField
        label="ID"
        source="id"
      />
      <ReferenceField
        label="Эвент"
        source="items.0.productId"
        reference="events"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField
        label="Хэрэглэгч"
        source="userId"
        reference="users"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField
        label="Төлсөн дүн"
        source="items.0.amount"
      />
      <TextField
        label="Төлөв"
        source="status"
      />
      <FunctionField label="" render={record => renderRejectButton(record.status, record.id)} />
    </Datagrid>
  </List>
);

export const PurchaseOrderCreateForEvent = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Эвент"
        source="eventId"
        reference="events"
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        label="Хэрэглэгч"
        source="userId"
        reference="users"
      >
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
