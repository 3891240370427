import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
import { PLAN_CHOICES } from '../constants';

const SubscriptionChangeBtn = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [planType, setPlanType] = useState(PLAN_CHOICES[0].id)
  const [coupon, setCoupon] = useState('');
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`userSubscriptions/${record.userId}`, { data:{ planType, coupon } })
      .catch((e) => {
        console.log(e)
        notify('Error: code not activated', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
    <>
      <select value={planType.id} onChange={(ev) => setPlanType(ev.target.value)}>
        {PLAN_CHOICES.map((plan) => <option value={plan.id}>{plan.name}</option>)}
      </select>
      <input placeholder="coupon" value={coupon} maxLength={8} minLength={6} onChange={(ev) => setCoupon(ev.target.value.toUpperCase())}></input>
      <Button onClick={handleClick} label="Идэвжүүлэх" disabled={loading} />
    </>
  );
};

export default SubscriptionChangeBtn;
