import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FunctionField,
  Filter,
  BooleanField,
  BooleanInput,
} from 'react-admin';

const getStatus = (url) => {
  if (url === null) {
    return "Processing";
  }
  return "Finished";
}

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" alwaysOn/>
    <TextInput label="CourseId" source="courseId" alwaysOn/>
  </Filter>
);

const VideoAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const VideoList = props => (
  <List {...props} filters={<TextFilter/>} actions={<VideoAction props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField source="courseId" reference="skills/courses">
        <TextField label="Хичээлийн нэр" source="title" />
      </ReferenceField>
      <TextField label="Нэр" source="title" />
      <TextField label="Дараалал" source="order" />
      <FunctionField label="Статус" render={record => getStatus(record.url)} />
      <BooleanField label="Үнэгүй үзэх" source="isPreview"/>
      <EditButton />
    </Datagrid>
  </List>
);

const VideoTitle = ({ record }) => {
  const { id } = record;
  return <span>Video {record ? `"${id}"` : ''}</span>;
};

export const VideoEdit = props => (
  <Edit title={<VideoTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput fullWidth source="courseId" reference="skills/courses" validate={[required()]} perPage={500}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Нэр" source="title" validate={[required()]} />
      <TextInput multiline fullWidth rows={5} label="Танилцуулга" source="description" />
      <ImageField source="thumbnailImage" label="Зураг" />
      <FileInput source="imgFile" label="Зураг" accept="image/*">
        <FileField source="img" title="image" />
      </FileInput>
      <FileInput source="file" label="Бичлэг" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
    </SimpleForm>
  </Edit>
);

export const VideoCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput fullWidth source="courseId" reference="skills/courses" validate={[required()]} perPage={500}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Нэр" source="title" validate={[required()]} />
      <TextInput multiline fullWidth rows={5} label="Танилцуулга" source="description" />
      <FileInput source="imgFile" label="Зураг" accept="image/*">
        <FileField source="img" title="image" />
      </FileInput>
      <FileInput source="file" label="Бичлэг" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
    </SimpleForm>
  </Create>
);
