import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';

export const GamingBalanceList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="Хэрэглэгчийн ID" source="userId" />
      <TextField label="Experience" source="xp" />
      <TextField label="Diamond" source="diamond" />
      <TextField label="Түвшин" source="level" />
    </Datagrid>
  </List>
);