import React, { useState } from 'react';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import Switch from '@material-ui/core/Switch';

const VerifiedMentorToggleBtn = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const { verified } = record;
  const refresh = useRefresh();
  const toggle = () => {
    setLoading(true);
    dataProvider.patch(`mentors/${record.id}/verified`, { data:{ verified: !verified } })
      .catch((e) => {
        console.log(e)
        notify('Server Error occured', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
      <Switch checked={verified} onChange={() => toggle()} disabled={loading} />
  );
};

export default VerifiedMentorToggleBtn;
