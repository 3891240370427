import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import httpClient from './../utils/httpClient';

const VideoJS = ({ videoId }) => {
  const [meta, setMeta] = React.useState({});
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const options = {
    controls: true,
    width: 500,
    sources: [{
      src: meta.hlsUrl,
      type: 'application/x-mpegURL',
    }]
  }

  React.useEffect(() => {
    if (meta) {
      videojs.Vhs.xhr.beforeRequest = (reqOptions) => {
        const policy = meta.cookie['CloudFront-Policy']
        const keyPair = meta.cookie['CloudFront-Key-Pair-Id']
        const signature = meta.cookie['CloudFront-Signature']

        const params = `?Policy=${policy}&Key-Pair-Id=${keyPair}&Signature=${signature}`
        reqOptions.uri = reqOptions.uri + params
        return reqOptions
      };
    }

    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, options);
    } else {
      const player = playerRef.current
      options.sources && player.src(options.sources)
    }
  }, [meta, options]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    httpClient(`${process.env.REACT_APP_BACKEND_URL}/video/meta`, {
      method: 'POST',
      body: JSON.stringify({ videoId }),
    }).then(({ json }) => setMeta(json));
  }, [videoId]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
}

export default VideoJS;
