import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
import { PURCHASE_ORDER_TAGS } from '../../constants';

const ChangeTagPurchaseOrderBtn = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [tag, setTag] = useState(PURCHASE_ORDER_TAGS[0].name)
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`skills/purchaseOrders/${record.id}/tag`, { data: { tag } })
      .catch((e) => {
        console.log(e)
        notify('Error: code not activated', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
    <>
      <select value={tag} onChange={(ev) => setTag(ev.target.value)}>
        {PURCHASE_ORDER_TAGS.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
      </select>
      <Button onClick={handleClick} label="Өөрчлөх" disabled={loading} />
    </>
  );
};

export default ChangeTagPurchaseOrderBtn;
