import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  EditButton,
  SelectInput,
  ReferenceField,
 } from 'react-admin';

export const MenteeTimeRequestList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="Student name" source="userId" reference="users">
        <TextField label="Student name" source="firstName" />
      </ReferenceField>
      <TextField label="mentorEmail" source="mentorEmail" />
      <TextField label="Status" source="status" />
      <TextField disabled label="Request text" source="requestText" />
      <EditButton />
    </Datagrid>
  </List>
);

export const MenteeTimeRequestEdit = props => (
  <Edit {...props}>
    <SimpleForm>
        <TextField source="id" />
        <TextField disabled label="Student name" source="user.firstName" />
        <TextField disabled label="Mentor email" source="mentorEmail" />
        <SelectInput
          label="status"
          source="status"
          choices={[
            { id: 'pending', name: 'pending' },
            { id: 'accepted', name: 'accepted' },
            { id: 'rejected', name: 'rejected' },
            { id: 'requested', name: 'requested' },
          ]}
        />
        <TextField disabled label="Request text" source="requestText" />
    </SimpleForm>
  </Edit>
);
