import React from 'react';
import {
  List,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  DateField,
  DateTimeInput,
  BooleanField,
  SelectInput,
  required,
} from 'react-admin';
import { MENTORING_DURATION } from '../constants';

export const MentorTimeSlotList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="Id" source="id" />
      <DateField label="Эхлэх цаг" source="starting" showTime/>
      <DateField label="Дуусах цаг" source="ending" showTime/>
      <BooleanField label="Товлосон эсэх" source="booked" />
    </Datagrid>
  </List>
);

export const MentorTimeSlotCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <DateTimeInput label="Эхлэх цаг" helperText="Улаанбаатарын (+08:00) цагаар" validate={required()} source="starting"/>
        <SelectInput label="Үргэлжлэх хугацаа" source="duration" choices={MENTORING_DURATION} />
      </SimpleForm>
    </Create>
  )
