import React from 'react';
import { NotificationTypes } from '../constants/Notification';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  required,
  minLength,
  maxLength,
  number,
  List,
  Create,
  Datagrid,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  DateField,
  SelectInput,
  NumberInput,
} from 'react-admin';

const validatePhone = [number()];
const validateMessage = [required(), minLength(10), maxLength(150)];

const Action = props => {
  const {
    basePath,
  } = props

  return (
    <Toolbar>
      <CreateButton label="Message Илгээх" basePath={basePath} />
    </Toolbar>
  );
};

export const NotificationList = (props) => (
  <List {...props} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Type" source="type" />
      <TextField label="Phone number" source="phone" />
      <TextField label="Message" source="message" />
      <DateField label="Илгээсэн огноо" source="createdAt" />
    </Datagrid>
  </List>
);

export const NotificationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={NotificationTypes} defaultValue={'SMS'} />
      <NumberInput label="Phone number" source="phone" validate={validatePhone} />
      <FileInput source="file" label="Targeted users for PUSH Notification" accept="text/csv">
        <FileField source="targetedUsers" title="CSV file" />
      </FileInput>
      <TextInput multiline fullWidth rows={5} label="Message" source="message" validate={validateMessage} />
    </SimpleForm>
  </Create>
);
