import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  Filter,
  ExportButton,
  BooleanField,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';

const ReferrerEmailFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Урьсан хэрэглэгч имэйл" source="email" alwaysOn />
  </Filter>
);

const UserReferralActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const ReferralsList = props => (
  <List {...props} filters={<ReferrerEmailFilter/>} actions={<UserReferralActions props={props} />}>
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField label="Урьсан хэрэглэгч имэйл" source="referrerId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Урьсан social" source="referrerId" reference="users">
        <TextField source="social" />
      </ReferenceField>
      <ReferenceField label="Уригдсан хэрэглэгч имэйл" source="referredId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Уригдсан social" source="referredId" reference="users">
        <TextField source="social" />
      </ReferenceField>
      <BooleanField label="Утаснаас орсон эсэx " source="mobileLogin"/>
    </Datagrid>
  </List>
);
