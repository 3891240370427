
import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Edit,
  Create,
  TextInput,
  SimpleForm,
  DateTimeInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';

export const GameChallengeList = props => (
  <List {...props}>
    <Datagrid optimized rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Cost" source="cost" />
      <TextField label="Prize" source="prize" />
      <TextField label="Start" source="start" />
      <TextField label="End" source="end" />
      <TextField label="Description" source="description" />
      <TextField label="XP per question" source="xpPerQuestion" />
    </Datagrid>
  </List>
);

export const GameChallengeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput label="Нэр" source="name" />
      <NumberInput label="XP" helperText="Зөв хариулт тус бүрд өгөх xp" source="xpPerQuestion" />
      <NumberInput label="Тасалбарын үнэ" source="cost" helperText="Тэмцээнд оролцохын тулд төлөх диамонд"/>
      <NumberInput label="Шагнал" source="prize" helperText="Тэмцээнд оролцсон хүнд өгөх диамонд" />
      <TextInput label="Тайлбар" source="description" fullWidth helperText="Нэмэлт шагнал өгөх бол энд бичиж болно" />
      <DateTimeInput label="Эхлэх" source="start" showTime />
      <DateTimeInput label="Дуусах" source="end" showTime />
      <NumberInput label="limit" source="questionLimit" />
    </SimpleForm>
  </Create>
);

export const GameChallengeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextField label="ID" source="id" />
      <TextInput label="Нэр" source="name" />
      <TextInput label="XP" helperText="Зөв хариулт тус бүрд өгөх xp" source="xpPerQuestion" />
      <NumberInput label="Тасалбарын үнэ" source="cost" helperText="Тэмцээнд оролцохын тулд төлөх диамонд" />
      <NumberInput label="Шагнал" source="prize" helperText="Тэмцээнд оролцсон хүнд өгөх диамонд" />
      <TextInput label="Тайлбар" source="description" fullWidth helperText="Нэмэлт шагнал өгөх бол энд бичиж болно" />
      <DateTimeInput label="Эхлэх" source="start" showTime />
      <DateTimeInput label="Дуусах" source="end" showTime />
      <NumberInput label="limit" source="questionLimit" />
    </SimpleForm>
  </Edit>
);
