import React from 'react';
import { MENTOR_TOPICS } from '../constants/AskingMentor';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
  ReferenceField,
  NumberInput,
  SelectInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import ChangeQnAStatusButton from './ChangeQnAStatusButton'

const TextFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Text" source="questionText" alwaysOn />
      <NumberInput label="Mentor ID" source="mentorId" alwaysOn />
  </Filter>
);

const AskingMentorAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </Toolbar>
  );
};

export const AskingMentorList = props => (
<List {...props} filters={<TextFilter/>} actions={<AskingMentorAction props={props} />}>
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="Менторын чиглэл" source="questionType" />
      <TextField disabled label="Mentor ID" source="mentorId" />
      <ReferenceField source="userId" reference="users">
        <TextField label="Нэр" source="firstName" />
      </ReferenceField>
      <TextField disabled rows={8} source="questionText" />
      <TextField label = "Status" source="status" />
      <EditButton />
      <ChangeQnAStatusButton />
    </Datagrid>
  </List>
);

export const AskingMentorEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput label="Менторын чиглэл" source="questionType" choices={MENTOR_TOPICS} validate={[required()]} />
      <TextInput disabled label="Mentor ID" source="mentorId" />
      <TextInput disabled label="Mentee ID" source="userId" />
      <TextInput multiline fullWidth rows={8} source="questionText" />
      <RichTextInput fillWidth label="Хариулт" source="answerText" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]}/>
    </SimpleForm>
  </Edit>
);
