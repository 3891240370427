import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  FileField,
  FileInput,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
} from 'react-admin';

export const ClassChallengeBannerList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="title" source="title" />
      <TextField label="linkUrl" source="linkUrl" />
      <TextField label="bannerType" source="bannerType" />
      <TextField label="status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ClassChallengeBannerEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="title" source="title" />
      <TextInput label="description" source="description" />
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput label="linkUrl" source="linkUrl" />
      <SelectInput
        label="banner-ийн төрөл"
        source="bannerType"
        choices={[{ id: 'CLASS_CHALLENGE', name: 'class_challenge' }]}
      />
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const ClassChallengeBannerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="title" source="title" />
      <TextInput label="description" source="description" />
      <ImageField title="Зураг" source="thumbnailImage" />
      <FileInput  label="Зураг" source="file" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput label="linkUrl" source="linkUrl" />
      <SelectInput
        label="banner-ийн төрөл"
        source="bannerType"
        choices={[{ id: 'CLASS_CHALLENGE', name: 'class_challenge' }]}
      />
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
