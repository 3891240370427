import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  Filter,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="name" alwaysOn />
  </Filter>
);

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const TagList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Нэр" source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

const TagTitle = ({ record }) => {
  const { name } = record;
  return <span>Tag {record ? `"${name}"` : ''}</span>;
};

export const TagEdit = props => (
  <Edit title={<TagTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Нэр" source="name" />
    </SimpleForm>
  </Edit>
);

export const TagCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Нэр" source="name" />
    </SimpleForm>
  </Create>
);
