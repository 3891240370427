import React from 'react';
import {
  List,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  EditButton,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  AutocompleteInput,
} from 'react-admin';
import { VIDEO_TYPES } from '../constants';
import { CONTENT_TYPES } from '../constants/ContentType';

const getReference = (contentType) => {
  switch(contentType) {
    case 'course':
      return 'courses';
    default:
      return 'courses';
  }
}

const getReferenceName = (contentType) => {
  switch(contentType) {
    case 'course':
      return 'topic';
    default:
      return 'name';
  }
}

const SubTaggingInputs = props => {
  const {contentType, ...rest} = props;
  switch(contentType) {
    case 'course':
      return (
        <React.Fragment>
          <SelectInput
            label="Видео контентын төрөл"
            source="videoType"
            choices={VIDEO_TYPES}
            {...rest}
          />
          {/* <ReferenceArrayInput source="levelIds" label="Харгалзах level" reference="levels" {...rest}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>           */}
        </React.Fragment>
      )
    default: return null;
  }
}

export const TaggingList = props => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Контент" source="taggableId" reference="courses">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Хэл" source="tagId" reference="languages">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField label="Улс" reference="tagging/countries" target="taggable_id">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
);

export const TaggingCreate = props => {
  return(
    <Create {...props}>
      <SimpleForm>
        <SelectInput label="Таглах контентийн төрөл" source="taggableType"  choices={CONTENT_TYPES} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceInput label="Контент" source="taggableId" filterToQuery={q => ({ topic: q })} reference={getReference(formData.taggableType)}>
              <AutocompleteInput optionText={getReferenceName(formData.taggableType)} />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <ReferenceInput label="Хэл" source="languageId" reference="languages">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="countryIds" label="Харгалзах улсууд" reference="countries">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <SubTaggingInputs contentType={formData && formData.taggableType} {...rest} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
