import React from 'react';
import { CreateButton, ExportButton } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import ImportButton from './gamingQuestionImportButton';
import {
  Datagrid,
  List,
  Edit,
  TextField,
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  ArrayField,
  SelectInput,
  EditButton,
  ReferenceArrayInput,
  SelectArrayInput,
  useDataProvider,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  ImageField,
  ReferenceField,
  FileInput,
  FileField,
  Filter,
} from 'react-admin';

const GAME_QUESTION_TYPES = [
  { id: 'SINGLE', name: 'SINGLE' },
  { id: 'MULTI', name: 'MULTI' },
];

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Асуулт" source="txt" alwaysOn />
  </Filter>
);


const QuestionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    basePath,
    exporter,
  } = props;
  const dataProvider = useDataProvider();

  const onParseData = data => {
    dataProvider.importCSV('TGQuestions', { data: Object.values(data) });
  };

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton onParseData={onParseData} />
    </Toolbar>
  );
};

export const TGQuestionList = props => (
  <List {...props} filters={<TextFilter/>} actions={<QuestionActions props={props} />}>
    <Datagrid optimized>
    <TextField label="ID" source="id" />
      <TextField label="Type" source="type" />
      <ReferenceField source="categoryId" reference="gamingCategories">
        <TextField label="Нэр" source="name" />
      </ReferenceField>
      <ReferenceField source="challengeId" reference="gamingChallenges">
        <TextField label="Нэр" source="name" />
      </ReferenceField>

      <TextField label="Асуулт" source="txt" />
      <ArrayField source="choices">
        <Datagrid>
          <TextField label="Хариулт" source="txt" />
        </Datagrid>
      </ArrayField>
      <TextField label="status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

const TGQuestionTitle = ({ record }) => {
    const { id } = record;
    return <span>Question {record ? `"${id}"` : ''}</span>;
  };

  export const TGQuestionEdit = props => (
    <Edit title={<TGQuestionTitle />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <SelectInput source="type" choices={GAME_QUESTION_TYPES} />
        <TextInput multiline fullWidth rows={5} label="Асуулт" source="txt" />
        <ReferenceInput source="categoryId" reference="gamingCategories" perPage={25}>
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="challengeId" reference="gamingChallenges" perPage={25}>
          <SelectInput optionText="name" optionValue="id" />  
        </ReferenceInput> 
        <ReferenceArrayInput label="Tag" source="tags" reference="gameTags" perPage={500}>
          <SelectArrayInput optionText="name" optionValue="name" />
        </ReferenceArrayInput>
        <ImageField source="photo" label="Current photo" />
        <FileInput source="file" accept="image/*">
          <FileField source="photo" />
        </FileInput>
        <ArrayInput source="choices">
          <SimpleFormIterator>
            <TextInput label="Хариулт" source="txt" />
            <BooleanInput
              label="Зөв хариулт эсэх"
              source="correctAnswer"
              default="false"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="статус"
          source="status"
          choices={[
            { id: 'draft', name: 'драфтласан' },
            { id: 'published', name: 'нийтлэсэн' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );

  export const TGQuestionCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <SelectInput source="type" choices={GAME_QUESTION_TYPES} />
        <TextInput multiline fullWidth rows={5} label="Асуулт" source="txt" />
        <ReferenceInput source="categoryId" reference="gamingCategories" perPage={25}>
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput source="challengeId" reference="gamingChallenges" perPage={25}>
          <SelectInput optionText="name" optionValue="id" />  
        </ReferenceInput> 
        <ReferenceArrayInput label="Tag" source="tags" reference="gameTags" perPage={500}>
          <SelectArrayInput optionText="name" optionValue="name" />
        </ReferenceArrayInput>
        <ImageField source="photo" label="Current photo" />
        <FileInput source="file" accept="image/*">
          <FileField source="photo" />
        </FileInput>
        <ArrayInput source="choices">
          <SimpleFormIterator>
            <TextInput label="Хариулт" source="txt" />
            <BooleanInput
              label="Зөв хариулт эсэх"
              source="correctAnswer"
              default="false"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="статус"
          source="status"
          choices={[
            { id: 'draft', name: 'драфтласан' },
            { id: 'published', name: 'нийтлэсэн' },
          ]}
        />
      </SimpleForm>
  </Create>
  )
