import React from 'react';
import { Box } from '@material-ui/core';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  SelectInput,
  TextInput,
  FunctionField,
  SimpleForm,
  EditButton,
  Filter,
  SaveButton,
  Toolbar
} from 'react-admin';
import {
  ReviewTypes,
  ReviewStatus,
} from '../constants/Review';

const UserEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const ReviewFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Төрөл"
      emptyText="Бүгд"
      source="revieweeType"
      choices={ReviewTypes}
      fullWidth
      alwaysOn
    />
    <TextInput
      label="Төрөл ID"
      source="revieweeId"
      fullWidth
      alwaysOn
    />
    <TextInput
      label="Төрөлийн ParentID"
      source="revieweeParentId"
      fullWidth
      alwaysOn
    />
    <SelectInput
      label="Төлөв"
      emptyText="Бүгд"
      source="status"
      choices={ReviewStatus}
      fullWidth
      alwaysOn
    />
  </Filter>
);

const getTypeById = (id) => ReviewTypes.find(type => type.id === id).name;
const getStatusById = (id) => ReviewStatus.find(type => type.id === id).name;

export const ReviewList = props => (
  <List {...props} filters={<ReviewFilter />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Сэтгэгдэл" source="body" />
      <FunctionField label="Үнэлгээ" render={record => `${record.rate} / 5`} />
      <FunctionField label="Төрөл" render={record => getTypeById(record.revieweeType)} />
      <TextField label="Төрөл ID" source="revieweeId" />
      <TextField label="Төрөлийн ParentID" source="revieweeParentId" />
      <FunctionField label="Төлөв" render={record => getStatusById(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

export const ReviewEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextField label="ID" source="id" />
      <SelectInput label="Үнэлгээ" source="rate" choices={[
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
      ]} />
      <TextInput label="Мэдээлэл" source="body" />
      <Box minWidth="25%" maxWidth="25%">
        <SelectInput
          fullWidth
          source="status"
          label="Төлөв"
          choices={ReviewStatus}
        />
      </Box>
    </SimpleForm>
  </Edit>
);
