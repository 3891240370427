import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  FileInput,
  FileField,
  ImageField,
  BooleanInput,
  DateTimeInput,
  Filter,
  CreateButton,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CONTENT_STATUS } from '../constants';
import JOURNEY_TYPES from '../constants/JourneyTypes';

const JourneyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="title" source="title" alwaysOn /> 
  </Filter>
);

const JourneyActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const JourneyList = props => (
  <List {...props} filters={<JourneyFilter/>} actions={<JourneyActions props={props} />}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="title" source="title" />
      <TextField label="tag" source="tag" />
      <TextField label="duration" source="duration" />
      <TextField label="description" source="description" />
      <TextField label="status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

const Title = ({ record }) => {
  const { title } = record;
  return <span>Journey {record ? `"${title}"` : ''}</span>;
};

export const JourneyEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <CloneButton />
      <TextInput disabled source="id" />
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput fullWidth label="title" source="title" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceInput label="Tag" source="tag" reference="tags" perPage={500}>
        <AutocompleteInput optionText="name" optionValue="name" options={{ suggestionsContainerProps: { disablePortal: true } }} />
      </ReferenceInput>
      <TextInput multiline label="duration" source="duration" />
      <RichTextInput label="description" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <ImageField source="thumbnail" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <FileInput source="documentFile" label="Хөтөлбөрийн материал" accept="application/pdf, image/*, application/zip, application/octet-stream">
        <FileField title="title" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'completed', name: 'дууссан' },
        ]}
      />
      <SelectInput
        label="type"
        source="type"
        choices={JOURNEY_TYPES}
      />
      <DateTimeInput label="Start time" helperText="Улаанбаатарын (+08:00) цагаар" source="startTime"/>
      <DateTimeInput label="End time" helperText="Улаанбаатарын (+08:00) цагаар" source="endTime"/>
      <NumberInput label="price" source="price" />
      <ArrayInput source="journeyCoordinators" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput disabled label="corrdinatorId" source="id" />
          <ReferenceInput source="userId" reference="users" validate={[required()]}>
            <AutocompleteInput
              optionText={item => (item.email ? item.email : '')}
              shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
              options={{ suggestionsContainerProps: { disablePortal: true } }}
            />
          </ReferenceInput>
          <BooleanInput label="Анги удирдсан багш эсэх" source="isPrimary" defaultValue={false} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput label="Шаардлагаууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const JourneyCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} />
      <TextInput label="title" source="title" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceInput label="Tag" source="tag" reference="tags" perPage={500}>
        <AutocompleteInput optionText="name" optionValue="name" options={{ suggestionsContainerProps: { disablePortal: true } }} />
      </ReferenceInput>
      <TextInput label="duration" source="duration" />
      <RichTextInput label="description" source="description" toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <FileInput source="documentFile" label="Хөтөлбөрийн материал" accept="application/pdf, image/*, application/zip, application/octet-stream">
        <FileField title="title" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={CONTENT_STATUS}
      />
     <SelectInput
        label="type"
        source="type"
        choices={JOURNEY_TYPES}
      />
      <DateTimeInput label="Start time" helperText="Улаанбаатарын (+08:00) цагаар" source="startTime"/>
      <DateTimeInput label="End time" helperText="Улаанбаатарын (+08:00) цагаар" source="endTime"/>
      <NumberInput label="price" source="price" />
      <ArrayInput source="journeyCoordinators" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput disabled label="corrdinatorId" source="id" />
          <ReferenceInput source="userId" reference="users" validate={[required()]}>
            <AutocompleteInput
              optionText={item => (item.email ? item.email : '')}
              shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
              options={{ suggestionsContainerProps: { disablePortal: true } }}
            />
          </ReferenceInput>
          <BooleanInput label="Анги удирдсан багш эсэх" source="isPrimary" defaultValue={false} />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput
        fullWidth
        label="Юу юунаас бүрдэж байгаа вэ?"
        source="includes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Хүлээж буй зүйлс" source="outcomes">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput fullWidth label="Шаардлагууд" source="requirements">
        <SimpleFormIterator>
          <TextInput fullWidth label="Сэдэв" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
