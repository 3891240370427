export const ReviewTypes = [
  { id: 'mentor', name: 'Ментор' },
  { id: 'course', name: 'Хичээл' },
  { id: 'lingoJourney', name: 'Багц' },
];

export const ReviewStatus = [
  { id: 'published', name: 'Нийтлэсэн' },
  { id: 'draft', name: 'Драфтласан' },
]
