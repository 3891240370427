const isUndefined = value => value === undefined;

const isNull = value => value === null;

const isObject = value => value === Object(value);

const isArray = value => Array.isArray(value);

const isDate = value => value instanceof Date;

const isBlob = value =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

const isFile = value =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

const objectToFormData = (obj, config = { indices: true, nullsAsUndefineds: true }, formData, pre) => {
  config = config || {};
  config.indices = isUndefined(config.indices) ? false : config.indices;
  config.nullsAsUndefineds = isUndefined(config.nullsAsUndefineds)
    ? false
    : config.nullsAsUndefineds;
  formData = formData || new FormData();

  if (isUndefined(obj)) {
    return formData;
  } else if (isNull(obj)) {
    if (!config.nullsAsUndefineds) {
      formData.append(pre, '');
    }
  } else if (isArray(obj)) {
    if (!obj.length) {
      const key = pre + '[]';

      formData.append(key, '');
    } else {
      obj.forEach((value, index) => {
        const key = pre + '[' + (config.indices ? index : '') + ']';

        objectToFormData(value, config, formData, key);
      });
    }
  } else if (isDate(obj)) {
    formData.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach(prop => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = pre ? pre + '[' + prop + ']' : prop;

      objectToFormData(value, config, formData, key);
    });
  } else {
    formData.append(pre, obj);
  }

  return formData;
};

export default objectToFormData;
