import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  EditButton,
  ReferenceArrayInput,
  SelectArrayInput,
  FunctionField,
  SelectInput,
  BooleanInput,
  required,
  ReferenceInput
} from 'react-admin';

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

export const QuizBunleList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const QuizBundleTitle = ({ record }) => {
  const { id } = record;
  return <span>Quiz Bundle {record ? `"${id}"` : ''}</span>;
};

export const QuizBundleEdit = props => (
  <Edit title={<QuizBundleTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <TextInput fullWidth label="Title" source="title" />
      <ImageField source="thumbnailImage" label="Зураг" />
      <FileInput source="file" accept="image/*">
        <FileField source="thumbnailImage"/>
      </FileInput>
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хичээлийн түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const QuizBundleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} /> 
      <TextInput fullWidth label="Title" source="title" />
      <ImageField source="thumbnailImage" label="Зураг" />
      <FileInput source="file" accept="image/*">
        <FileField source="thumbnailImage"/>
      </FileInput>
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хичээлийн түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
