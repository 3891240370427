import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { connect } from 'react-redux';
import { parse as convertFromCSV } from 'papaparse';

const ImportButton = props => {
  const { onParseData } = props;

  const handleChange = e => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      convertFromCSV(file, {
        delimiter: ',',
        complete: (result) => {
          const { data } = result;

          const keys = data[0];
          const choiceKeyIndex = keys.indexOf("choices");

          let prevIndex = 0;
          const values = {};
          data.slice(1).forEach((row, rowIndex) => {

            const value = { choices: [] };
            if (row[0] === "") {
              values[prevIndex]['choices'].push({txt: row[choiceKeyIndex]});
            } else {
              keys.forEach((key, index) => {
                if (key.slice(0, 6) === "choice") {
                  value.choices.push({txt: row[index]});
                } else if(["correctAnswer", "tags"].includes(key)){
                  value[key] = row[index].split(";");
                }else{
                  value[key] = row[index];
                }
              });
              values[rowIndex] = value;
              prevIndex = rowIndex;
            }

          });
          onParseData(values);
        }
      });
    }
  };

  return (
    <>
      <input
        type="file"
        id="text-button-file"
        style={{ display: 'none' }}
        accept='.csv'
        onChange={handleChange}
      />
      <label htmlFor="text-button-file" style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Button
          color="primary"
          component="span"
        >
          <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
          <span style={{ paddingLeft: '0.5em' }}>Import</span>
        </Button>
      </label>
    </>
  );
};

export default connect()(ImportButton);