import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  FileInput,
  FileField,
  TextInput,
  SimpleForm,
  EditButton,
  Create,
  SelectInput
} from 'react-admin';

export const GamingCategoryList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Дараалал" source="order" />
      <TextField label="Нэр" source="name" />
      <TextField label="icon" source="icon" />
      <TextField label="Уриа" source="motto" />
      <TextField label="Зөв хариултын тоо" source="correctCount" />
      <TextField label="Зөв хариултын diamond" source="correctCountDiamond" />
      <TextField label="Амжилтаа эвдвэл өгөх diamond" source="breakRecordDiamond" />
      <TextField label="Streak-ээ үргэлжлүүлэх diamond" source="continueStreakDiamond" />
      <TextField label="Асуулт бүрд өгөх xp" source="xpPerQuestion" />
      <TextField label="status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);
const CategoryTitle = ({ record }) => {
  const { id } = record;
  return <span>Category {record ? `"${id}"` : ''}</span>;
};

export const GamingCategoryEdit = props => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Дараалал" source="order" />
      <TextInput label="Нэр" source="name" />
      <TextInput label="Уриа" source="motto" />
      <TextField label="icon" source="icon" fullWidth />
      <FileInput source="file" accept="image/png">
        <FileField source="icon" />
      </FileInput>
      <TextInput label="Зөв хариулах тоо" source="correctCount" />
      <TextInput
        label="Зөв хариулвал өгөх diamond"
        source="correctCountDiamond"
      />
      <TextInput
        label="Амжилтаа эвдвэл өгөх diamond"
        source="breakRecordDiamond"
      />
      <TextInput
        label="Streak-ээ үргэлжлүүлэх diamond"
        source="continueStreakDiamond"
      />
      <TextInput
        label="Асуулт бүрд өгөх xp"
        source="xpPerQuestion"
      />
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const GamingCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Дараалал" source="order" />
      <TextInput label="Нэр" source="name" />
      <TextInput label="Уриа" source="motto" />
      <FileInput source="file" accept="image/png">
        <FileField source="icon" />
      </FileInput>
      <TextInput label="Зөв хариулах тоо" source="correctCount" />
      <TextInput
        label="Зөв хариулвал өгөх diamond"
        source="correctCountDiamond"
      />
      <TextInput
        label="Амжилтаа эвдвэл өгөх diamond"
        source="breakRecordDiamond"
      />
      <TextInput
        label="Streak-ээ үргэлжлүүлэх diamond"
        source="continueStreakDiamond"
      />
      <TextInput
        label="Асуулт бүрд өгөх xp"
        source="xpPerQuestion"
      />
       <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
