import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
import { MENTOR_QNA_STATUSES } from '../constants/AskingMentor';

const ChangeQnAStatusButton = ({record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [status, setStatus] = useState(MENTOR_QNA_STATUSES[0])
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`askingMentors/${record.id}/status`, { data:{ status } })
      .catch((e) => {
        console.log(e)
        notify('Error: Status hasn\'nt been changed', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <>
      <select value={status} onChange={(ev) => setStatus(ev.target.value)}>
        {MENTOR_QNA_STATUSES.map((status) => <option key={status} value={status}>{status}</option>)}
      </select>
      <Button onClick={handleClick} label="Төлөв өөрчлөх" disabled={loading} />
    </>
  );
};

export default ChangeQnAStatusButton;
