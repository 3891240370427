import React from 'react';
import {
  required,
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  Filter,
  TextInput,
  FunctionField,
  SelectInput,
} from 'react-admin';
import { PURCHASE_ORDER_TAGS } from '../../constants';
import RejectPurchaseOrderBtn from './RejectPurchaseOrderBtn';
import ChangeTagPurchaseOrderBtn from './ChangeTagPurchaseOrderBtn';
const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Id" source="userId" />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

const getButton = (status, id) => {
  if (status === 'APPROVED') {
    return <RejectPurchaseOrderBtn id={id}/>;
  }
  return '';
}

export const PurchaseOrderList = props => (
  <List {...props} filters={<OrderFilter/>}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Хичээл" source="items.0.courseId" reference="skills/courses">
        <TextField label="Хичээл" source="title" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" label="Имэйл">
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Төлбөр" source="items.0.amount" />
      <TextField label="Статус" source="status" />
      <FunctionField label="" render={record => getButton(record.status, record.id)} />      
      <TextField label="Төрөл" source="tag" />
      <ChangeTagPurchaseOrderBtn/>
    </Datagrid>
  </List>
);

export const PurchaseOrderCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users" validate={[required()]}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput label="Хичээл" source="courseId" filterToQuery={q => ({ title: q })} reference="skills/courses" validate={[required()]}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <SelectInput
        label="Төрөл"
        source="tag"
        defaultValue={PURCHASE_ORDER_TAGS[0].name}
        choices={PURCHASE_ORDER_TAGS}
      />
    </SimpleForm>
  </Create>
);
