import React from 'react';
import MaterialToolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  SaveButton,
  Toolbar,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { MENTOR_QNA_STATUSES } from '../constants/AskingMentor';

const TextFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Асуултаар хайх" source="questionText" alwaysOn />
  </Filter>
);

const getStatus = (status) => {
  if (status === MENTOR_QNA_STATUSES[2]) {
    return "Хариулсан";
  }
  return "Хариулаагүй";
}


const AskingMentorAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <MaterialToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </MaterialToolbar>
  );
};

export const MentorsQuestionsList = props => (
<List {...props} filters={<TextFilter/>} actions={<AskingMentorAction props={props} />}>
    <Datagrid optimized>
      <TextField label="№" source="id" />
      <FunctionField label="Хэрэглэгч" render={({ user }) => user ? `${user.lastName} ${user.firstName}` : null} />
      <TextField label="Асуулт" disabled rows={6} source="questionText" />
      <DateField label="Огноо" source="createdAt" locales="mn-MN"  />
      <FunctionField label="Төлөв" source="status" render={record => (
        <Chip color={record.status ===  MENTOR_QNA_STATUSES[1] ? 'default': 'secondary'} label={getStatus(record.status)} />
      )}
      />
      <EditButton label="Хариулах" />
    </Datagrid>
  </List>
);

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton label="Хариулах" />
  </Toolbar>
);

export const MentorsQuestionsEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput label="Асуулт" disabled multiline fullWidth source="questionText" />
      <RichTextInput
        fillWidth
        label="Хариулт"
        source="answerText"
        toolbar={[ ['bold', 'italic', 'underline', 'link'], [{ 'list': 'ordered'}, {'list': 'bullet'}] ]}
      />
    </SimpleForm>
  </Edit>
);
