import React from 'react';
import { QUIZ_TYPES } from '../constants/Quiz';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  FileField,
  FileInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  BooleanField,
  BooleanInput,
  required,
} from 'react-admin';

export const QuizList = (props) => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Төрөл" source="type" />
      <TextField label="Гарчиг" source="title" />
      <TextField label="Хугацаа" source="totalDuration" />
      <TextField label="Tag" source="languageTags" />
      <TextField label="Асуултын тоо" source="numberOfQuestion" />
      <BooleanField label="Үнэгүй үзэх" source="isPreview"/>
      <EditButton />
    </Datagrid>
  </List>
);

const QuizTitle = ({ record }) => {
  const { title } = record;
  return (
    <span>
Quiz
      {record ? `"${title}"` : ''}
    </span>
  );
};

export const QuizEdit = (props) => (
  <Edit title={<QuizTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput source="type" choices={QUIZ_TYPES} />
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Гарчиг" source="title" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <TextInput label="Хугацаа" source="totalDuration" />
      <ReferenceInput fullWidth label="Бүлэг сонгох" source="bundleId" reference="quizBundles" perPage={200}>
        <SelectInput optionText="title" optionValue="id"/>
      </ReferenceInput>
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <ArrayInput fullWidth source="instructions">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Заавар" />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageField source="thumbnailImage" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <NumberInput label="Асуултын тоо" source="numberOfQuestion" />
      <SelectInput source="questionsThrough" label="Асуулт холбох" choices={[{id: 'tags', name: 'Хэл болон түвшингээр'}, {id:'quizId', name:'Тестийн ID-гаар'}]} helperText="Хэрвээ quizId-гаар татах бол харгалзал асуултууд орсон эсэхийг шалгаарай!" />
    </SimpleForm>
  </Edit>
);

export const QuizCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={QUIZ_TYPES} />
      <BooleanInput label="Үнэгүй үзэж болох эсэх" source="isPreview" />
      <TextInput fullWidth label="Гарчиг" source="title" />
      <ReferenceInput label="Хэл" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput  label="Хэлний түвшин" source="tagging.languageLevels" reference="languageLevels">
        <SelectArrayInput optionText="level" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Tag" source="languageTags" reference="tags" perPage={500}>
        <SelectArrayInput optionText="name" optionValue="name" />
      </ReferenceArrayInput>
      <TextInput label="Хугацаа" source="totalDuration" />
      <ReferenceInput fullWidth label="Бүлэг сонгох" source="bundleId" reference="quizBundles" perPage={200}>
        <SelectInput optionText="title" optionValue="id"/>
      </ReferenceInput>
      <TextInput label="Дараалал" source="order" pattern="[0-9]{1,3}" />
      <ArrayInput fullWidth source="instructions">
        <SimpleFormIterator>
          <TextInput fullWidth source="value" label="Заавар" />
        </SimpleFormIterator>
      </ArrayInput>
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <NumberInput label="Асуултын тоо" source="numberOfQuestion" />
      <SelectInput source="questionsThrough" label="Асуулт холбох" choices={[{id: 'tags', name: 'Хэл болон түвшингээр'}, {id:'quizId', name:'Тестийн ID-гаар'}]} helperText="Хэрвээ quizId-гаар татах бол харгалзал асуултууд орсон эсэхийг шалгаарай!" />
    </SimpleForm>
  </Create>
);
